import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Leo
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
             
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Leo</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Leo</h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-love");
                  }}>
                  <h5 className="font-semibold text-black">Leo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Leo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-man");
                  }}>
                  <h5 className="font-semibold text-black">Leo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Leo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Leo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Leo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              <h1 className="playfair text-black text-xl md:text-3xl">About Leo</h1>
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Sanskrit/Vedic name:
              </p>{" "}
              Simha
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Cancer Meaning
              </p>
              : The Lion
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Type:{" "}
              </p>
              Fire - Fixed - Positive
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Leo Sign
              </p>
              <br />
              <br />
              Leo is the fifth sign and it is the central sign of the fiery
              triplicity of the zodiac circle. The sign is ruled by a proud and
              fierce lion. The energy of the sign is expressive and flamboyant
              in nature. It is the sign of the lion, the emperor, and the pope.
              It is the ruler of the zodiac and symbolizes strength, power, and
              authority. The lion is a proud and fiery animal, and its symbol is
              a lion because these qualities reflect the sign’s name. The sign
              expresses bold leadership, a courageous attitude, and adventurous
              qualities.
              <br />
              <br />
              <p className="playfair text-black text-xl md:text-3xl">
                How to recognize Leo?
                </p>
              
              <br />
              The natives under this sign are natural leaders and dazzle others
              with a gorgeous smile. They are a strong believer in the greatest
              leader, the most generous and warm-hearted of all the signs, the
              protector, the actor, the seducer, the symbol of courage, the
              voice of the people, the symbol of power and strength, the symbol
              of justice, the symbol of leadership and success, the symbol of
              masculinity and of pride, the symbol of the sexual and physical
              force, the symbol of the passionate and irrepressible, the symbol
              of the warrior and the conqueror… almost every quality that we
              associate with a king or a king of beasts can be found in a Leo.
              <br />
              <br />
              Leo as a person is someone who is so self-centered that he can’t
              even tell you what he wants to do in a situation without first
              telling you what he has decided to do. This sounds like the
              opposite of being a good collaborator, but it’s actually a great
              way to get along with a Leo.
              <br />
              <br />
              They are dramatic, spontaneously creative, self-confident,
              extroverts, and dominant. They usually come up with dramatic
              statements and actions so that they can be a center of attraction.
              They don't like being alone, and they need a lot of affirmation.
              The natives have a flush of pride or ego on their blush.
              <br />
              <br />
              At the same time, in this zodiac sign, people are always ready to
              achieve everything that they desire by hook and crook. Apart from
              achieving things in their life, they are loyal and faithful
              friends too. Also, they will have a deep love for their life. They
              usually display their arrogant pride and their sunny playfulness
              at a time.
              <br />
              <br />
              They urge respect and do not prefer to take orders from others.
              Under whatever condition they are in, they will not defend their
              rights and dignity. They're desired to be noticed every time by
              everyone, this always rumoring around their head, and constantly
              they try to maintain their aura of influence.
              <br />
              <br />
              Generally, they are very generous and friendly with others. They
              listen to others politely with a slightly patronizing style. But
              they never have an offending smile.
              <br />
              <br />
              Leo people are warm and loving. They radiate positive energy, and
              their presence is deeply inspiring. They bring joy and happiness
              to those around them, and they're always looking to help others.
              Because let is such natural leaders, they often find themselves in
              positions of authority, which they are well-suited for.
              <br />
              <br />
              A Leo person will bear more stoically in the face of hardship or
              adjust more courageously to complex conditions. They will face
              these conditions with sheer faith and optimism, believing that a
              positive outcome is imminent. Their determination can be a source
              of great strength, even at overwhelming odds. They simply love it
              when everyone depends on them and they have to carry all the
              loads.
              <br />
              <br />
              To know more about your personality traits, let’s explore about
              leo. Ask our expert Astrologers! <br />
              <br />
              <p className="playfair text-black text-xl md:text-3xl">All About Leo</p>
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">Domicile:</p> The Sun<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Detriment:</p> Saturn and Uranus<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Exaltation:</p> Pluto<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Fall:</p>Neptune<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Element:</p>Fire<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Quality:</p>Fixed<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Symbol:</p>The Lion<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Tarot card:</p> Strength<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Fellow signs:</p> Aries, Sagittarius <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Compatible signs: </p>Aries, Gemini, Libra, Sagittarius<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Most Compatible Signs: </p>Gemini<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Opposite Signs:</p> Aquarius<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">The Symbol denotes:</p> Commanding, fiery, daring and kingly, yet the generous attitude<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Characteristics: </p>Fiery, fixed, masculine, strong, bitter<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Noteworthy qualities:</p> Loyalty, kindness, tolerance, generosity, inspiration, philanthropy, fearlessness, chivalry, optimism, intuitiveness, industriousness.<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Undesirable personality traits: </p>Arrogance, domination, guilelessness, fussiness, anger, sensitivity, impetuousness, impatience, bluntness.<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Favorable things:</p>Movies and dramas, taking holidays, being admired, expensive things, bright colors, fun time with friends<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Things they hate:</p>Being ignored, facing difficult reality, not being treated like a king or queen, not being in the limelight<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Birthstone:</p> Ruby<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky stones: </p>Topaz, Carnelian, Diamond<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky Metals:</p> Gold<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky day: </p>Sunday<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lucky number:</p> 1, 4 , 10, 13, 19, 22, 28<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Birth Colours: </p>Orange, Yellow, Gold<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Deepest need:</p> Elation, need to shine and rule<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Lifetime wish:</p> To rule the world<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Mantra for life: </p>I would love to rise and shine<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">The quality most needed for balance:</p>Humility<br/><br/>

            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
